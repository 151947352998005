<template>
  <div>
    <we-chat-map></we-chat-map>
  </div>
</template>

<script>
  import weChatMap from "../components/weChatMap/weChatMap";
export default {
  name: "smartMap",
  // eslint-disable-next-line vue/no-unused-components
  components: {weChatMap},
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  },
}
</script>

<style scoped lang="">

</style>
